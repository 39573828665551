// export delay function
export default function getParentsFlatArrayIds (item) {
  if (item.parents.length === 0) {
    return [item.id]
  } else {
    const result = item.parents.map(x => {
      return [getParentsFlatArrayIds(x), item.id]
    })
    return result
  }
}
