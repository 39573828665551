<template>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card v-if="!loading">
          <b-container>
            <b-row>
              <b-col sm="12" md="7">
                <p>
                  <b-link v-if="$config.BUILD === 'full' || ($config.BUILD === 'external' && permission.impactinitiatives)" class="mr-3" :to="{ name: 'ImpactInitiatives' }">all initiatives</b-link>
                  <b-button class="mr-3" v-if="permission.edit && step.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: editModel }}">Edit</b-button>
                  <b-button class="mr-3" v-if="permission.addObligation && step.edit" variant="outline-primary" @click="addObligation()">New obligation</b-button>
                  <delete-standard
                    v-if="permission.delete && step.delete"
                    :id="item.id"
                    model="impactinitiative"
                  ></delete-standard>
                  <b-link class="mr-3" :to="{ name: 'ImpactInitiativeLog', params: { id: item.id }}">version history</b-link>
                </p>
                <h2>
                  {{item.name}}
                </h2>
                <div class="mb-2">
                  <span v-if="$route.name === 'ImpactPoliciesInitiative'"><b-link :to="{ name: 'ImpactPoliciesCountry', params: { country: item.country } }">{{item.country}}</b-link></span>
                  <span v-if="$route.name !== 'ImpactPoliciesInitiative'">{{item.country}}</span>
                  | Implementation status: {{item.impactstatus.name}}
                </div>
                <div v-if="item.impactpolicy_id > 0">
                  <div><h4 class="mt-5">Policy details</h4></div>
                  <policy-initiative-details :item="item" />
                </div>
                <div class="mb-2" style="font-size: 1.2em; font-weight: 500">
                  <new-lines :content="item.headline"></new-lines>
                </div>
                <div v-html="item.description"></div>
                <div class="my-5" v-if="showEditScope && permission.edit && step.edit">
                  <scoping-form :top="true" v-on:save="showEditScope = false" v-model="item.scoping" :id="item.id" />
                </div>
                <div v-if="$config.BUILD === 'full' && permission.edit">
                  <div><h4 class="mt-5">Reviewers' notes</h4></div>
                  {{ item.reviewers_notes }}
                </div>
                <div v-if="$config.BUILD === 'full'">
                  <div class="mt-5"><small>{{item.created_by}} created on {{moment(item.created_at).format('YYYY-MM-DD')}}</small></div>
                  <div><small>{{item.updated_by}} last updated on {{moment(item.updated_at).format('YYYY-MM-DD')}}</small></div>
                </div>
                <div v-if="item.obligations.length > 0 && $config.BUILD === 'full'">
                  <h4 class="mt-5">Obligations</h4>
                  <b-form inline>
                    sort by: <b-form-select class="mr-2" v-model="obligationsSortSelected" :options="obligationsSortOptions"></b-form-select>
                    <b-button class="mr-3" v-if="permission.addObligation && step.edit" variant="outline-primary" @click="addObligation()">New obligation</b-button>
                  </b-form>
                  <div class="my-4" v-for="obligation in obligationsShow"  :key="'obligation-'+ obligation.id">
                    <div style="font-size: 1.25em" v-if="obligation.obligationtype"><b-badge :variant="badgeVariant(obligation.obligationtype.name)">{{obligation.obligationtype.name}}</b-badge></div>
                    <h5><b-link class="mr-3" :to="{ name: 'CommoditiesObligation', params: { id: obligation.id }}">{{obligation.name}}</b-link> <b-button class="mr-3" v-if="permission.addObligation" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: obligation.id, model: 'obligation' }}">Edit</b-button></h5>
                    <div><strong>description</strong></div>
                    <div class="mb-2"><new-lines :content="obligation.description"></new-lines></div>
                    <div><strong>context / entity scope</strong></div>
                    <div class="mb-2"><new-lines :content="obligation.context"></new-lines></div>
                    <div v-if="obligation.dates.length > 0">
                      <div v-for="date in obligation.dates" :key="'date' + date.id">
                        <strong>{{date.date}}</strong> - {{date.name}}
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div v-if="$config.BUILD === 'full'">
                  <h4>Workflow</h4>
                  <div>status: <strong>{{step.name}}</strong></div>
                  <div v-if="item.workflow_created_by"><small>{{item.workflow_created_by}} at {{moment(item.workflow_created_at).format('YYYY-MM-DD HH:mm')}}</small></div>
                  <div>
                    <span v-if="!savingStep && permission.edit">
                      <b-button class="mr-4" v-if="step.downStep && permissionWorkflowDown" variant="outline-primary" size="sm" @click="stepUpdate(step.down)">move to: {{step.downStep.name}}</b-button>
                      <b-button v-if="step.upStep && permissionWorkflowUp" variant="outline-primary" size="sm" @click="stepUpdate(step.up)">move to: {{step.upStep.name}}</b-button>
                    </span>
                    <span v-if="savingStep">
                      <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
                    </span>
                  </div>
                </div>
                <div v-if="$config.BUILD === 'full'">
                  <h4 class="mt-5">Dataset</h4>
                  <div style="font-size: 1.2em">
                    <span v-if="!item.faqproject">none</span>
                    <span v-if="item.faqproject">{{item.faqproject.name}}</span>
                  </div>
                </div>
                <div v-if="item.impactpolicy_id">
                  <h4 class="mt-5">Policy</h4>
                  <div style="font-size: 1.2em"><b-link :to="{ name: 'ImpactPoliciesPolicy', params: { id: item.impactpolicy.id } }">{{item.impactpolicy.name}}</b-link></div>
                </div>
                <div v-if="item.impactexperts.length > 0">
                  <h4 class="mt-5">Specialists</h4>
                  <div v-for="expert in item.impactexperts" :key="'expert'+expert.id" style="font-size: 1.2em">
                    <div v-if="$config.BUILD === 'full'">
                      <router-link :to="{ name: 'Expert', params: { id: expert.id }}">{{expert.name}}</router-link>
                    </div>
                    <div v-else>{{expert.name}}</div>
                  </div>
                </div>
                <div v-if="item.issued || item.effective || item.first_reporting">
                  <h4 class="mt-5">Dates</h4>
                  <div v-if="item.issued">Issued: {{item.issued}}</div>
                  <div v-if="item.effective">Effective: {{item.effective}}</div>
                  <div v-if="item.first_reporting">First reporting: {{item.first_reporting}}</div>
                </div>
                <div v-if="item.reportingfrequency">
                  <div v-if="item.reportingfrequency.id != 1">
                    <h4 class="mt-5">Reporting frequency</h4>
                    <div>{{item.reportingfrequency.name}}</div>
                  </div>
                </div>
                <div v-if="item.report_type">
                  <h4 class="mt-5">Report type</h4>
                  <div>{{item.report_type}}</div>
                </div>
                <div v-if="item.reportingframeworks.length > 0">
                  <h4 class="mt-5">Reporting frameworks</h4>
                  <div v-for="row in item.reportingframeworks" :key="'entity'+row.id" class="mb-2">
                    {{row.name}}
                  </div>
                </div>
                <div v-if="item.assurance">
                  <div v-if="item.assurance.id != 1">
                    <h4 class="mt-5">Assurance</h4>
                    <div>{{item.assurance.name}}</div>
                  </div>
                </div>
                <div v-if="item.mandatory || item.optional || item.comply_explain">
                  <h4 class="mt-5">Reporting obligation</h4>
                  <div>
                    <span v-if="item.mandatory">mandatory | </span>
                    <span v-if="item.optional">optional | </span>
                    <span v-if="item.comply_explain">comply or explain</span>
                  </div>
                </div>
                <div v-if="item.tax_coverage">
                  <h4 class="mt-5">Tax coverage</h4>
                  <div><new-lines :content="item.tax_coverage"></new-lines></div>
                </div>
                <div v-if="item.tax_base">
                  <h4 class="mt-5">Tax base</h4>
                  <div>{{item.tax_base}}</div>
                </div>
                <div v-if="item.tax_local_rate">
                  <h4 class="mt-5">Tax rate %</h4>
                  <div>{{item.tax_local_rate}}</div>
                </div>
                <div v-if="item.tax_ccy_rate">
                  <h4 class="mt-5">Tax rate in local currency</h4>
                  <div>{{item.tax_ccy_rate}}</div>
                </div>
                <div v-if="item.tax_local_rate_currency">
                  <h4 class="mt-5">Tax rate local currency</h4>
                  <div>{{item.tax_local_rate_currency}}</div>
                </div>
                <div v-if="item.tax_normalised_rate">
                  <h4 class="mt-5">Tax normalised rate (USD)</h4>
                  <div>{{item.tax_normalised_rate}}</div>
                </div>
                <div v-if="item.tax_scheduled_increases">
                  <h4 class="mt-5">Tax scheduled increases</h4>
                  <div>{{item.tax_scheduled_increases}}</div>
                </div>
                <div v-if="item.tax_penalty">
                  <h4 class="mt-5">Tax penalty for non-compliance</h4>
                  <div>{{item.tax_penalty}}</div>
                </div>
                <div v-if="item.tax_exemptions">
                  <h4 class="mt-5">Tax exemptions available</h4>
                  <div>{{item.tax_exemptions}}</div>
                </div>
                <div v-if="item.tax_incentive_base">
                  <h4 class="mt-5">Tax incentive base</h4>
                  <div>{{item.tax_incentive_base}}</div>
                </div>
                <div v-if="item.tax_incentive_rate">
                  <h4 class="mt-5">Tax incentive rate %</h4>
                  <div>{{item.tax_incentive_rate}}</div>
                </div>
                <div v-if="item.tax_incentive_currency">
                  <h4 class="mt-5">Tax incentive local currency</h4>
                  <div>{{item.tax_incentive_currency}}</div>
                </div>
                <div v-if="item.tax_incentive_normalised">
                  <h4 class="mt-5">Tax incentive normalised rate (USD)</h4>
                  <div>{{item.tax_incentive_normalised}}</div>
                </div>
                <div v-if="item.tax_conditions_for_relief">
                  <h4 class="mt-5">Tax conditions for relief</h4>
                  <div>{{item.tax_conditions_for_relief}}</div>
                </div>
                <div v-if="item.tax_incentive_time">
                  <h4 class="mt-5">Tax incentive time restrictions</h4>
                  <div>{{item.tax_incentive_time}}</div>
                </div>
                <div v-if="item.impacttopics.length > 0">
                  <h4 class="mt-5">Topics</h4>
                  <div v-for="topic in item.impacttopics" :key="'topic'+topic.id" style="font-size: 1.2em">
                    <div v-if="$config.BUILD === 'full'">
                      <router-link :to="{ name: 'ImpactTopic', params: { id: topic.id }}">{{topic.name}}</router-link>
                    </div>
                    <div v-else>{{ topic.name }}</div>
                  </div>
                </div>
                <div v-if="item.regulators.length > 0">
                  <h4 class="mt-5">Regulators</h4>
                  <div v-for="row in item.regulators" :key="'entity'+row.id" class="mb-2">
                    <strong>{{row.name}}</strong><br />{{row.comments}}
                  </div>
                </div>
                <div v-if="item.entities.length > 0">
                  <h4 class="mt-5">Entities</h4>
                  <div v-for="row in item.entities" :key="'entity'+row.id" class="mb-2">
                    {{row.name}}
                  </div>
                </div>
                <div v-if="item.events.length > 0">
                  <h4 class="mt-5">Events</h4>
                  <div v-for="event in item.events" :key="'event'+event.id" class="mb-3">
                    <router-link :to="{ name: 'Event', params: { id: event.id }}"><strong>{{moment(event.date).format('YYYY-MM-DD')}}</strong> - {{event.name}}</router-link>
                  </div>
                </div>
                <div v-if="item.regulations.length > 0">
                  <h4 class="mt-5">Regulations</h4>
                  <div v-for="row in item.regulations" :key="'regulation'+row.id" class="mb-3">
                    <div v-if="$config.BUILD === 'full'">
                      <router-link :to="{ name: 'RegDecompWorkspace', params: { id: row.id }}">{{row.name_short}}</router-link>
                    </div>
                    <div v-else>{{ row.name_short }}</div>
                  </div>
                </div>
                <div v-if="item.articles.length > 0">
                  <h4 class="mt-5">Insights</h4>
                  <div v-for="row in item.articles" :key="'article'+row.id" class="mb-3">
                    <router-link :to="{ name: 'Article', params: { id: row.id }}">{{row.title}}</router-link>
                  </div>
                </div>
                <div v-if="item.publications.length > 0">
                  <h4 class="mt-5">News</h4>
                  <div v-for="row in item.publications" :key="'newsitem'+row.id" class="mb-3">
                    <router-link :to="{ name: 'NewsItem', params: { id: row.id }}">{{row.name}}</router-link>
                  </div>
                </div>
                <div v-if="item.impactinitiativeslinks.length > 0">
                  <h4 class="mt-5">External links</h4>
                  <div v-for="link in item.impactinitiativeslinks" :key="'link'+link.id" class="mb-3">
                    <div><a :href="link.url" target="_blank">{{link.name}}</a></div>
                  </div>
                </div>
                <div v-if="item.controls.length > 0">
                  <h4 class="mt-5">Controls</h4>
                  <div v-for="row in item.controls" :key="'control'+row.id" class="mb-2">
                    <div v-if="$config.BUILD === 'full'">
                      <router-link :to="{ name: 'Control', params: { id: row.id } }">{{row.name}}</router-link><br />
                    </div>
                    <div v-else>{{ row.name }}</div>
                  </div>
                </div>
                <div v-if="item.taxonomy.length > 0">
                  <initiative-taxonomy
                    :rows="item.taxonomy"
                  >
                  </initiative-taxonomy>
                </div>
                <div v-if="item.sectors.length > 0">
                  <h4 class="mt-5">Sectors</h4>
                  <div v-for="row in item.sectors" :key="'sector'+row.id">
                    <div>
                      {{ row.sector }}
                      <span v-if="row.industrygroup"> | {{ row.industrygroup }}</span>
                      <span v-if="row.industry"> | {{ row.industry }}</span>
                      <span v-if="row.subindustry"> | {{ row.subindustry }}</span>
                    </div>
                  </div>
                </div>
                <div v-if="item.impactpolicy_id > 0 && !loadingNews && news.length > 0">
                  <h4 class="mt-5">Policy News</h4>
                  <div v-for="item in news" :key="'news'+item.id" class="my-3">
                    <div><b-link :to="{ name: 'NewsItem', params: { id: item.id }}">{{item.name}}</b-link></div>
                    <div><small>{{item.publisher}} - {{moment(item.date).format('YYYY-MM-DD')}}</small></div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import DeleteStandard from '@/components/DeleteStandard'
import PolicyInitiativeDetails from '@/components/PolicyInitiativeDetails'
import moment from 'moment'
import NewLines from '@/components/NewLines.vue'
import InitiativeTaxonomy from '@/components/taxonomy/InitiativeTaxonomy.vue'
import workflow from '@/workflows/initiative'

export default {
  name: 'CommoditiesInitiative',
  components: {
    DeleteStandard,
    NewLines,
    PolicyInitiativeDetails,
    InitiativeTaxonomy
  },
  computed: {
    editModel: function () {
      if (this.$route.name === 'TaxInitiative') {
        return 'impactinitiative-tax'
      }
      if (this.$route.name === 'AiInitiative') {
        return 'impactinitiative-ai'
      }
      return 'impactinitiative'
    },
    initiatives: {
      get () {
        return this.$store.state.commoditiesInitiatives
      },
      set (payload) {
        this.$store.commit('setCommoditiesInitiatives', payload)
      }
    },
    obligationsShow: function () {
      let result = this.item.obligations
      if (result.length > 0) {
        _.each(result, x => {
          x.type = x.obligationtype.name
        })
        if (this.obligationsSortSelected === 'id') {
          result = _.sortBy(result, ['id'])
        }
        if (this.obligationsSortSelected === 'name') {
          result = _.sortBy(result, ['name'])
        }
        if (this.obligationsSortSelected === 'type') {
          result = _.sortBy(result, ['type'])
        }
      }
      return result
    },
    permissionWorkflowDown: function () {
      let result = false
      if (this.step.id > 8 && ac.can(this.user.acgroups).readAny('initiativeWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id <= 100 && ac.can(this.user.acgroups).createAny('impactinitiative').granted) {
        result = true
      }
      return result
    },
    permissionWorkflowUp: function () {
      let result = false
      if (this.step.id >= 8 && ac.can(this.user.acgroups).readAny('initiativeWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id < 8 && ac.can(this.user.acgroups).createAny('impactinitiative').granted) {
        result = true
      }
      return result
    },
    step: function () {
      const step = workflow.find(x => x.id === this.item.workflow_id)
      step.downStep = workflow.find(x => x.id === step.down)
      step.upStep = workflow.find(x => x.id === step.up)
      return step
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'impact initiative', action: 'open impact initiative', model: 'impactinitiative', model_id: this.$route.params.id })
    this.permission.delete = ac.can(this.user.acgroups).deleteAny('impactinitiative').granted
    this.permission.addObligation = ac.can(this.user.acgroups).createAny('impactobligation').granted
    this.load()
  },
  data () {
    return {
      badgeVariant: function (type) {
        let res = 'primary'
        if (type) {
          if (type.toLowerCase() === 'obligation') {
            res = 'danger'
          }
          if (type.toLowerCase() === 'entity') {
            res = 'success'
          }
          if (type.toLowerCase() === 'context') {
            res = 'info'
          }
        }
        return res
      },
      item: null,
      loading: true,
      loadingNews: true,
      moment: moment,
      news: [],
      obligationsSortOptions: ['id', 'name', 'type'],
      obligationsSortSelected: 'name',
      permission: {
        delete: false,
        edit: false,
        impactinitiative: false
      },
      savingStep: false,
      showEditScope: false,
      showScopeAssessment: false
    }
  },
  methods: {
    addObligation: async function () {
      try {
        const params = {
          body: {
            impactinitiative_id: this.$route.params.id,
            username: this.user.username
          }
        }
        const obligation = await this.$Amplify.API.put('cosmos', '/impact/initiative/obligation', params)
        this.$router.push({ name: 'FormEdit', params: { id: obligation.id, model: 'impactobligation' } })
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    load: async function () {
      this.loading = true
      this.loadingNews = true
      try {
        this.item = await this.$Amplify.API.get('cosmos', `/impact/initiative/${this.$route.params.id}`)
        if (this.$config.BUILD === 'full') {
          if (!this.item.faqproject) {
            this.permission.edit = ac.can(this.user.acgroups).updateAny('impactinitiative').granted
          } else {
            const belongsToProject = await this.$Amplify.API.get('cosmos', `/belongstofaqproject/${this.item.faqproject.id}/${this.user.username}`)
            this.permission.edit = ac.can(this.user.acgroups).updateAny('impactinitiative').granted && belongsToProject.length > 0
          }
        }
        document.title = this.item.name + ' - ' + this.item.country
        this.loading = false
        if (this.item.impactpolicy_id > 0) {
          this.news = await this.$Amplify.API.get('cosmos', `/news/country/${this.item.country}/policy/${this.item.impactpolicy_id}`)
        }
        this.loadingNews = false
        if (this.initiatives.length > 0) {
          // update metric within metrics using id as a reference field
          const index = this.initiatives.findIndex(x => x.id === this.item.id)
          if (index === -1) {
            this.initiatives.push(this.item)
          } else {
            this.initiatives[index] = this.item
          }
        }
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    stepUpdate: async function (id) {
      this.savingStep = true
      try {
        const params = {
          body: {
            workflow_id: id,
            workflow_created_at: moment().toISOString(),
            workflow_created_by: this.user.username
          }
        }
        await this.$Amplify.API.put('cosmos', `/standard/impactinitiative/${this.item.id}`, params)
        this.item.workflow_id = id
        this.item.workflow_created_by = params.body.workflow_created_by
        this.item.workflow_created_at = params.body.workflow_created_at
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.savingStep = false
    }
  }
}
</script>

<style scoped>

</style>
